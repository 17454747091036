import may_be_phang from "../images/may_moc_thiet_bi/may_be_phang.png";
import may_in_4_mau from "../images/may_moc_thiet_bi/may_in_bon_mau.png";
import may_cat_cuon from "../images/may_moc_thiet_bi/may_cat_cuon.png";
import dan_song_5_lop from "../images/may_moc_thiet_bi/dan_song_5_lop.png";
import may_boi_tu_dong from "../images/may_moc_thiet_bi/may_boi_tu_dong.png";
import may_gim_tu_dong from "../images/may_moc_thiet_bi/may_gim_tu_dong.png";
import may_khac_ban_in from "../images/may_moc_thiet_bi/may_khac_ban_in.png";
import may_xeo_giay from "../images/may_moc_thiet_bi/may_xeo_giay.png";
import may_ban_ghim from "../images/may_moc_thiet_bi/may_ban_ghim.png";

export const menuAppBar = [
  {
    id: 1,
    name: "TRANG CHỦ",
    path: "/",
  },
  {
    id: 2,
    name: "GIỚI THIỆU",
    path: "/gioi-thieu",
  },
  {
    id: 3,
    name: "SẢN PHẨM",
    path: "/san-pham",
  },
  {
    id: 4,
    name: "TUYỂN DỤNG",
    path: "/tuyen-dung",
  },
  {
    id: 5,
    name: "HƯỚNG DẪN",
    path: "/huong-dan",
  },
  {
    id: 6,
    name: "TIN TỨC",
    path: "/tin-tuc",
  },

  {
    id: 7,
    name: "LIÊN HỆ",
    path: "/lien-he",
  },
];

export const machines = [
  {
    id: 1,
    name: "Máy xeo giấy",
    path: may_be_phang,
  },
  {
    id: 2,
    name: "Máy ghim",
    path: may_in_4_mau,
  },
  {
    id: 3,
    name: "Máy bổ chạp",
    path: may_cat_cuon,
  },
  {
    id: 4,
    name: "Máy nén vòng",
    path: may_ban_ghim,
  },
  {
    id: 5,
    name: "Dàn máy sóng",
    path: dan_song_5_lop,
  },
  {
    id: 6,
    name: "Máy bế",
    path: may_gim_tu_dong,
  },
  {
    id: 7,
    name: "Máy dán",
    path: may_boi_tu_dong,
  },
  {
    id: 8,
    name: "Máy đo độ ẩm",
    path: may_xeo_giay,
  },
  {
    id: 9,
    name: "Máy in Flexo 4 màu",
    path: may_khac_ban_in,
  },
  {
    id: 10,
    name: "Máy xả",
    path: may_khac_ban_in,
  },
  {
    id: 11,
    name: "Máy ra bản in",
    path: may_khac_ban_in,
  },
  {
    id: 12,
    name: "Máy kiểm tra độ bền chịu lực",
    path: may_khac_ban_in,
  },
  {
    id: 13,
    name: "Máy bắn độ bục",
    path: may_khac_ban_in,
  },
  {
    id: 14,
    name: "Máy bó",
    path: may_khac_ban_in,
  },
  {
    id: 15,
    name: "Xe gắp giấy cuốn",
    path: may_khac_ban_in,
  },
  {
    id: 16,
    name: "Cân định lượng",
    path: may_khac_ban_in,
  },
];
