import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  header: {
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 1,
    backgroundColor: "white",
  },
  menu: {
    margin: "0px auto",
    height: "57px",
    lineHeight: "60px",
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
  },
  iconMenuBox: {
    [theme.breakpoints.up(900)]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: 350,
  },
  activeLink: {
    color: "#308632 !important",
  },
  colorProductLink: {
    color: "#555A8B",
  },
  itemMenu: {
    textAlign: "center",
    color: "black",
    "&:hover": {
      color: "#308632",
    },
    padding: "10px 0px",
  },
  menuList: {
    fontSize: 20,
    fontWeight: "bold",
    color: "#313131",
    "&:hover": {
      color: "#308632",
    },
  },
}));
