import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  info: {
    width: 36,
    height: 36,
    borderRadius: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& svg": {
      fontSize: 14,
      color: "white",
    },
  },
  boxFooter: {
    [theme.breakpoints.between(0, 689)]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.between(690, 1000)]: {
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.up(1000)]: {
      justifyContent: "space-between",
    },
  },
}));
