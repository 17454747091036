import React, { useState } from "react";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import { Box, MenuItem, Menu } from "@material-ui/core";
import { useStyles } from "./Navigation.styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { menuAppBar } from "../../constants/constants";

const Navigation = () => {
  const classes = useStyles();

  const { allWpCategory } = useStaticQuery(graphql`
    query getCate {
      allWpCategory {
        nodes {
          name
          slug
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
  `);

  const isActiveProduct = React.useMemo(() => {
    return (
      window.location.pathname.includes("/danh-muc-san-pham/") ||
      window.location.pathname.includes("/san-pham/")
    );
  }, []);

  const convertDataProducts = React.useMemo(() => {
    return allWpCategory?.nodes?.filter(item => !item.wpParent);
  }, [allWpCategory]);

  const [anchorElProfileMenu, setAnchorElProfileMenu] = useState(null);

  const handleOpenProfileMenu = event => {
    setAnchorElProfileMenu(event.currentTarget);
  };

  return (
    <Box className={classes.menu}>
      {menuAppBar.map((item, index) => (
        <Box>
          <Link
            to={item.name === "SẢN PHẨM" ? undefined : item.path}
            partiallyActive={index !== 0}
            className={`${classes.menuList} ${
              item.name === "SẢN PHẨM" && isActiveProduct && classes.activeLink
            }`}
            activeClassName={classes.activeLink}
          >
            {item.name === "SẢN PHẨM" ? (
              <Box
                className={classes.menuDropDown}
                onClick={handleOpenProfileMenu}
                display="flex"
                alignItems="center"
              >
                <Box pl={2}>{item.name}</Box>
                <KeyboardArrowDownIcon />
              </Box>
            ) : (
              <Box px={2}>{item.name}</Box>
            )}
          </Link>
        </Box>
      ))}
      <Menu
        id="menu-profile"
        anchorEl={anchorElProfileMenu}
        keepMounted
        open={Boolean(anchorElProfileMenu)}
        onClose={() => setAnchorElProfileMenu(null)}
        transformOrigin={{
          vertical: -59,
          horizontal: "left",
        }}
        className={classes.menuPaper}
      >
        {convertDataProducts?.map(item => (
          <MenuItem onClick={() => navigate(`/danh-muc-san-pham/${item.slug}`)}>
            <Box className={classes.upCase} width="100%">
              {item.name}
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default Navigation;
