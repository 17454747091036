import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { useStyles } from "../../index.styles";
import { Link } from "gatsby";

const Support = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 1280px)");
  const breakpoints = useMediaQuery("(max-width: 1000px)");

  return (
    <Box
      width={breakpoints ? "95%" : "80%"}
      margin="auto"
      py={breakpoints ? 2 : 8}
    >
      <Box
        display={!breakpointsXs ? "flex" : undefined}
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Box>
          <Typography variant="h4">
            <b>Bạn cần support hoặc báo giá?</b>
          </Typography>
          <Box fontSize={25} mt={1}>
            <b>Vui lòng liên hệ với chúng tôi để nhận phản hồi sớm nhất</b>
          </Box>
        </Box>
        <Box my={2}>
          <Button
            component={Link}
            to="/lien-he"
            className={classes.buttonContact}
          >
            <Box color="white" display="flex" alignItems="center">
              <Box mr={1.5}>
                <FontAwesomeIcon icon={faPhoneAlt} />
              </Box>
              <b>LIÊN HỆ HỢP TÁC</b>
            </Box>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Support;
