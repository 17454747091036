import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Support from "../Support/Support";

import "animate.css";
import "./layout.styles.css";

const Layout = ({ children }) => {
  if (typeof window === "undefined") return <></>;
  return (
    <>
      <CssBaseline />
      <Header />
      {children}
      <Support />
      <Footer />
    </>
  );
};

export default Layout;
