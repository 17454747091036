import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  activeLink: {
    color: "#308632 !important",
    "& > div": {
      borderBottom: "4px solid green",
    },
  },
  menu: {
    display: "flex",
    justifyContent: "space-between",
  },
  menuList: {
    fontSize: 17,
    fontWeight: "bold",
    [theme.breakpoints.down(1210)]: {
      fontSize: 13,
    },
    color: "#313131",
    "&:hover": {
      color: "#308632",
    },
  },
  paper: {
    border: "1px solid",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
  menuDropDown: {
    cursor: "pointer",
  },
  upCase: {
    "&:first-letter": {
      textTransform: "uppercase",
    },
    fontSize: 18,
    marginLeft: "20px",
  },
  menuPaper: {
    "& .MuiMenu-paper": {
      width: "220px",
    },
  },
}));
