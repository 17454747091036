import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, useMediaQuery } from "@material-ui/core";
import React from "react";
import { useStyles } from "./Footer.styles";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  const classes = useStyles();
  const breakpointsXs = useMediaQuery("(max-width: 680px)");
  return (
    <Box bgcolor="black">
      <Box width="100%" maxWidth={1170} mx="auto">
        <Box
          width="100%"
          maxWidth={990}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          mx="auto"
          py={3}
          px={2}
          className={classes.boxFooter}
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="start">
            <Box className={classes.info} bgcolor="#0d47a1">
              <FontAwesomeIcon icon={faMapPin} style={{ width: "36px" }} />
            </Box>
            <Box color="#999" ml={1} fontSize={13}>
              <Box>
                <b>CÔNG TY TRÁCH NHIỆM HỮU HẠN 2-9</b>
              </Box>
              <b>TDP Ngô Gia Khảm - P.Châu Sơn - Tp. Phủ Lý - Hà Nam</b>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mr={2}
            ml={breakpointsXs ? 0 : 2}
            my={2}
          >
            <Box className={classes.info} bgcolor="#0084ff">
              <FontAwesomeIcon icon={faEnvelope} />
            </Box>
            <Box color="#999" ml={1} fontSize={13}>
              <b>congty29hn@gmail.com</b>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box className={classes.info} bgcolor="#308632">
              <FontAwesomeIcon icon={faPhoneAlt} />
            </Box>
            <Box color="#999" ml={1} fontSize={13}>
              <b>Hotline: 0226.3858.948</b>
            </Box>
          </Box>
        </Box>
        <Box bgcolor="#4A4A4A">
          <Divider />
        </Box>
        <Box fontSize={14} textAlign={"center"} py={2} color="#999">
          @{new Date().getFullYear()} - All Right Reserved.
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
