import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  buttonContact: {
    backgroundColor: "#308632",
    padding: "0px 30px",
    height: "56px",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  buttonViewDetail: {
    backgroundColor: "black",
    color: "white",
    height: "46px",
    padding: "0px 30px",
    "&:hover": {
      backgroundColor: "#308632",
    },
  },
  "@global": {
    ".MuiTypography-caption": {
      fontSize: 16,
    },
    ".MuiButtonBase-root": {
      borderRadius: "unset",
      borderWidth: "2px",
    },
    ".image": {
      objectFit: "cover",
      height: "100%",
      maxHeight: 299.5,
      width: "100%",
      transition: "all 0.3s",
      transform: "scale(1.02)",
      "&:hover": {
        backfaceVisibility: "hidden",
        transform: "scale(1.2)",
      },
    },
  },
  fullWidth: {
    width: "100%",
  },
  heighImage: {
    [theme.breakpoints.between(0, 400)]: {
      height: "250px",
    },
    [theme.breakpoints.between(400, 550)]: {
      height: "300px",
    },
    [theme.breakpoints.between(550, 650)]: {
      height: "350px",
    },
    [theme.breakpoints.between(650, 765)]: {
      height: "400px",
    },
    [theme.breakpoints.between(765, 1000)]: {
      height: "500px",
    },
    [theme.breakpoints.up(1000)]: {
      height: "250px",
    },
  },
  titleNew: {
    [theme.breakpoints.between(0, 1000)]: {
      display: "-webkit-box",
      lineClamp: 2,
      boxOrient: "vertical",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "break-spaces",
    },
    "& > a": {
      fontWeight: 700,
      color: "black",
      "&:hover": {
        color: "blue",
      },
    },
  },
  boxMain: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "&:nth-child(odd)": {
      flexDirection: "row-reverse",
    },
    [theme.breakpoints.down(765)]: {
      "&:nth-child(odd)": {
        flexDirection: "unset",
      },
    },
  },
  link: {
    cursor: "pointer",
    "&:hover": {
      color: "#308632",
    },
  },
  contentPost: {
    "& .wp-block-image > img": {
      width: "100%",
    },
  },
  news: {
    [theme.breakpoints.between(0, 1000)]: {
      width: "100%",
    },
    [theme.breakpoints.between(1000, 1150)]: {
      width: "46%",
    },
    [theme.breakpoints.between(1150, 1250)]: {
      width: "47%",
    },
    [theme.breakpoints.between(1250, 1440)]: {
      width: "48%",
    },
    [theme.breakpoints.up(1440)]: {
      width: "31.5%",
    },
  },
  imgDocument: {
    "& > img": {
      position: "absolute",
      top: "30%",
    },
  },
}));
