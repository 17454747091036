import React, { useState } from "react";
import { Link, useStaticQuery, navigate, graphql } from "gatsby";
import Navigation from "../Navigation/Navigation";
import { Box, Divider, Drawer, List, useMediaQuery } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import { useStyles } from "./Header.style";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { menuAppBar } from "../../constants/constants";
import CloseIcon from "@material-ui/icons/Close";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const Header = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const breakpoints = useMediaQuery("(max-width: 1000px)");
  const [isShowMore, setIsShowMore] = useState(false);

  const { allWpCategory } = useStaticQuery(graphql`
    query getCateHeader {
      allWpCategory {
        nodes {
          name
          slug
          wpParent {
            node {
              id
            }
          }
        }
      }
    }
  `);
  const convertDataProducts = React.useMemo(() => {
    return allWpCategory?.nodes?.filter(item => !item.wpParent);
  }, [allWpCategory]);

  const isActiveProduct = React.useMemo(() => {
    return (
      window.location.pathname.includes("/danh-muc-san-pham/") ||
      window.location.pathname.includes("/san-pham/")
    );
  }, []);

  return (
    <Box boxShadow={4} className={classes.header}>
      <Box
        display="flex"
        alignItems="center"
        mx="auto"
        justifyContent="space-between"
        width={breakpoints ? "95%" : "80%"}
      >
        <Link to="/">
          <StaticImage height={70} alt="logo" src="../../images/logo.jpg" />
        </Link>
        <Box className={classes.menu}>
          <Navigation />
        </Box>
        <Box className={classes.iconMenuBox}>
          <IconButton color="inherit" onClick={() => setOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Box>
      </Box>
      <Drawer
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box position="absolute" top={5} right={5}>
          <IconButton onClick={() => setOpen(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mt={8}>
          <Divider />
          <Box my={2}>
            <List>
              {menuAppBar.map(item => (
                <Box display="flex" justifyContent="center" fontSize={23}>
                  <Link
                    to={item.name === "SẢN PHẨM" ? undefined : item.path}
                    activeClassName={classes.activeLink}
                    className={`${classes.menuList} ${
                      item.name === "SẢN PHẨM" &&
                      isActiveProduct &&
                      classes.activeLink
                    }`}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      lineHeight="40px"
                    >
                      {item.name === "SẢN PHẨM" ? (
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={() => setIsShowMore(!isShowMore)}
                        >
                          {item.name}
                          <KeyboardArrowDownIcon
                            style={{ cursor: "pointer" }}
                          />
                        </Box>
                      ) : (
                        item.name
                      )}
                    </Box>
                    {isShowMore && item.name === "SẢN PHẨM" && (
                      <>
                        {convertDataProducts?.map(item => (
                          <Box
                            className={classes.itemMenu}
                            onClick={() =>
                              navigate(`/danh-muc-san-pham/${item?.slug}`)
                            }
                          >
                            {item?.name}
                          </Box>
                        ))}
                      </>
                    )}
                  </Link>
                </Box>
              ))}
            </List>
          </Box>
          <Divider />
        </Box>
      </Drawer>
    </Box>
  );
};

export default Header;
